import _ from 'lodash'
import axios from 'axios'
import * as Sentry from '@sentry/browser'

window._ = _

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

if (import.meta.env.PROD) {
    Sentry.init({
        dsn: 'https://27dbeb10699728f51ca5f1ce10415659@o117921.ingest.us.sentry.io/4508180884619264',
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
                // Specify the application keys that you specified in the Sentry bundler plugin
                filterKeys: ['eco-website'],

                // Defines how to handle errors that contain third party stack frames.
                // Possible values are:
                // - 'drop-error-if-contains-third-party-frames'
                // - 'drop-error-if-exclusively-contains-third-party-frames'
                // - 'apply-tag-if-contains-third-party-frames'
                // - 'apply-tag-if-exclusively-contains-third-party-frames'
                behaviour: 'drop-error-if-contains-third-party-frames',
            }),
            // Explicitly disable Session Replay
            Sentry.replayIntegration({
                disabled: true,
            }),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [],
        // Session Replay - add this config back, even though we removed it was still capturing
        // Now we set them to 0 to disable, just in case, as well as setting disabled to true
        replaysSessionSampleRate: 0,
        replaysOnErrorSampleRate: 0,
    })
}

setTimeout(() => {
    let embedo = new Embedo()
    Array.from(document.getElementsByTagName('oembed')).forEach((element) => {
        let d = document.createElement('div')
        element.parentNode.appendChild(d)
        embedo.load(d, element.getAttribute('url'))
    })
}, 1000)

